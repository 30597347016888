import { TileInstrumentChanged } from '../../../state/clientWorkspace';
import { FxAmericanForwardInputs } from '../../../state/fxAmericanForward/model/fxAmericanForwardProductModel';
import { metaSelectorTradeCaptureToBackendWith } from './tradeCaptureToBackend';
import { selectors } from '../../../state/selectors';
import { metaSelectorTradeCaptureFromBackendWith } from './tradeCaptureFromBackend';
import {
  americanForwardChangedToPatchWith,
  americanForwardRestoreToPatchWith,
} from './tradeCapturePatchBuilderFromAction';

export const americanForwardChangedToPatch = americanForwardChangedToPatchWith(selectors);
export const createAmericanForwardTradeCaptureRequest = metaSelectorTradeCaptureToBackendWith(selectors);
export const americanForwardResponseToPatchMetaSelector = metaSelectorTradeCaptureFromBackendWith(selectors);
export const americanForwardRestoreToPatch = americanForwardRestoreToPatchWith(selectors);

export const americanForwardTileInstrumentChangedToPatch = ({
  tileId,
  currencyPair,
}: TileInstrumentChanged): [string, Partial<FxAmericanForwardInputs>] => [tileId, { currencyPair, premiumPaymentAmount: "0" }];
