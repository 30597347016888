import { getAmericanForwardInput } from './factories';
import { createSelector } from 'reselect';
import { AppState } from 'state/model';
import { fieldData } from 'utils/fieldSelectors';

// TODO ABO, to refacto, less complexity/abstraction
// export const getAmericanForwardCurrencyPair = getAmericanForwardInput('currencyPair');
export const getAmericanForwardCurrencyPairInput = getAmericanForwardInput('currencyPair');
export const getAmericanForwardSide = getAmericanForwardInput('side');
export const getAmericanForwardStartDate = getAmericanForwardInput('startDate');
export const getAmericanForwardStartDateTenor = getAmericanForwardInput('startDateTenor');
export const getAmericanForwardExpiryDate = getAmericanForwardInput('expiryDate');
export const getAmericanForwardExpiryDateTenor = getAmericanForwardInput('expiryDateTenor');
export const getAmericanForwardDeliveryDate = getAmericanForwardInput('deliveryDate');
export const getAmericanForwardDeliveryDateTenor = getAmericanForwardInput('deliveryDateTenor');

export const getAmericanForwardMarketPlace = getAmericanForwardInput('marketPlace');

export const getAmericanForwardPriceType = getAmericanForwardInput('premiumTypeString');
export const getAmericanForwardCurrency = getAmericanForwardInput('priceCurrency');
export const getAmericanForwardPremiumDate = getAmericanForwardInput('premiumDate');
export const getAmericanForwardPremiumDateTenor = getAmericanForwardInput('premiumDateTenor');
export const getAmericanForwardPremiumPaymentAmount = getAmericanForwardInput('premiumPaymentAmount');
export const getAmericanForwardRate = getAmericanForwardInput('forwardRate');

export const getAmericanForwardHedgeCurrency = getAmericanForwardInput('hedgeCurrency');
export const getAmericanForwardHedgeAmount = getAmericanForwardInput('hedgeAmount');
export const getAmericanForwardHedgeType = getAmericanForwardInput('hedgeType');
export const getAmericanForwardHedgeRate = getAmericanForwardInput('hedgeRate');
export const getAmericanForwardMarkupCurrency = getAmericanForwardInput('markupCurrency');

export const getAmericanForwardAmount = getAmericanForwardInput('amount');
export const getAmericanForwardAmountCurrency = getAmericanForwardInput('amountCurrency');

export const getAmericanForwardCurrencyPairData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardCurrencyPairInput(state, quoteId),
    (currencyPair) => fieldData(currencyPair).data,
  );

export const getAmericanForwardHedgeRateData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardHedgeRate(state, quoteId),
    (hedgeRate) => fieldData(hedgeRate).data,
  );

export const getAmericanForwardHedgeCurrencyData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardHedgeCurrency(state, quoteId),
    (hedgeCurrency) => fieldData(hedgeCurrency).data,
  );

export const getAmericanForwardHedgeAmountData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardHedgeAmount(state, quoteId),
    (hedgeAmount) => fieldData(hedgeAmount).data,
  );

export const getAmericanForwardSideData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardSide(state, quoteId),
    (side) => fieldData(side).data,
  );

export const getAmericanForwardAmountCurrencyData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardAmountCurrency(state, quoteId),
    (AmountCurrency) => fieldData(AmountCurrency).data,
  );

export const getAmericanForwardAmountData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardAmount(state, quoteId),
    (notional) => fieldData(notional).data,
  );

export const getAmericanForwardStartDateData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardStartDate(state, quoteId),
    (startDate) => ({
      data: fieldData(startDate).data,
      isInput: fieldData(startDate).isInput,
    }),
  );

export const getAmericanForwardStartDateTenorData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardStartDateTenor(state, quoteId),
    (startDateTenor) => fieldData(startDateTenor).data,
  );

export const getAmericanForwardExpiryDateData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardExpiryDate(state, quoteId),
    (expiryDate) => ({
      data: fieldData(expiryDate).data,
      isInput: fieldData(expiryDate).isInput,
    }),
  );

export const getAmericanForwardExpiryDateTenorData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardExpiryDateTenor(state, quoteId),
    (expiryDateTenor) => fieldData(expiryDateTenor).data,
  );

export const getAmericanForwardDeliveryDateData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardDeliveryDate(state, quoteId),
    (deliveryDate) => ({
      data: fieldData(deliveryDate).data,
      isInput: fieldData(deliveryDate).isInput,
    }),
  );

export const getAmericanForwardDeliveryDateTenorData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardDeliveryDateTenor(state, quoteId),
    (expiryDateTenor) => fieldData(expiryDateTenor).data,
  );

export const getAmericanForwardPriceTypeData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardPriceType(state, quoteId),
    (priceType) => fieldData(priceType).data,
  );

export const getAmericanForwardCurrencyData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardCurrency(state, quoteId),
    (currency) => fieldData(currency).data,
  );

export const getAmericanForwardPremiumDateData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardPremiumDate(state, quoteId),
    (expiryDate) => ({
      data: fieldData(expiryDate).data,
      isInput: fieldData(expiryDate).isInput,
    }),
  );

export const getAmericanForwardPremiumDateTenorData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardPremiumDateTenor(state, quoteId),
    (expiryDateTenor) => fieldData(expiryDateTenor).data,
  );

export const getAmericanForwardPremiumPaymentAmountData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardPremiumPaymentAmount(state, quoteId),
    (premiumPaymentAmount) => fieldData(premiumPaymentAmount).data,
  );

export const getAmericanForwardRateData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardRate(state, quoteId),
    (forwardRate) => fieldData(forwardRate).data,
  );

export const getAmericanForwardHedgeTypeData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardHedgeType(state, quoteId),
    (hedgeType) => fieldData(hedgeType).data,
  );

export const getAmericanForwardMarkupCurrencyData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardMarkupCurrency(state, quoteId),
    (markupCurrency) => fieldData(markupCurrency).data,
  );

export const getAmericanForwardMarketPlaceData = (quoteId: string) =>
  createSelector(
    (state: AppState) => getAmericanForwardMarketPlace(state, quoteId),
    (marketPlace) => fieldData(marketPlace).data,
  );
