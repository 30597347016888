import {
  type ClientMap,
  type Instrument,
  type InstrumentChoice,
  isTileInstrument,
  type ProductsAccess,
} from '../referenceData';
import type { Thunk } from 'state';
import type { TabType } from 'state/clientWorkspace';
import type { GridItemPosition } from 'state/gridLayout/gridLayoutModels';
import { assertUnreachable, isDefined, isEmpty, isNonEmpty, isNotDefined } from '@sgme/fp';
import {
  AccumulatorSavedTile,
  AmericanForwardSavedTile,
  CashSavedTile,
  OptionSavedTile,
  SavedTab,
  SavedTile,
  SavedWorkspace,
  SwapSavedTile,
} from 'api/workspaceService/model';
import type { ProductName } from 'state/share/productModel';
import { distinct, getNearestNeighbour } from 'utils/array';
import {
  injectDefaultAccumulatorTab,
  injectDefaultCashTab,
  injectDefaultOptionTab,
  injectDefaultOrderTab,
} from 'state/blotter/utils';
import { extract } from 'utils/object';
import type { RestoredTiles } from './clientWorkspaceActions';
import { fieldData } from 'utils/fieldSelectors';
import type { TradeCaptureSessionInfos } from '../sharedSelectors';
import { PossibleSolvingPremium } from 'state/fxAmericanForward/model/fxAmericanForwardProductModel';

export function clientWorkspaceTabAddedThunk(type: TabType, tabClientId?: string, selectTab?: boolean): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac, getNewGuid }) => {
    const state = getState();
    const clients = sl.getAllUserClients(state);
    const clientId = tabClientId ?? getDefaultClientId(clients);
    const newTabId = getNewGuid();
    const tabName = sl.getClientWorkspaceTabNextName(state, type);
    dispatch(ac.clientWorkspaceTabAdded(newTabId, type, tabName, clientId));

    if (clientId !== null && sl.isUserInternalSales(state)) {
      dispatch(ac.orderRequestEmailsEpic(clientId, newTabId));
    }

    if (selectTab) {
      dispatch(ac.clientWorkspaceTabSwitched(newTabId));
    }
  };
}

export function clientWorkspaceTabClosedThunk(tabIdToClose: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const tabs = sl.getClientWorkspaceTabs(state);
    const activeTab = sl.getClientWorkspaceActiveTab(state);
    const tabToClose = tabs[tabIdToClose];

    if (tabToClose === undefined) {
      return;
    }

    if (activeTab === tabIdToClose) {
      const tabIds = Object.keys(tabs);
      const newTabToSelect = getNearestNeighbour(tabIds, tabIdToClose);
      if (newTabToSelect !== null) {
        dispatch(ac.clientWorkspaceTabSwitched(newTabToSelect));
      }
    }
    tabToClose.tiles.forEach((tileId) => dispatch(ac.tileClosedThunk(tileId, true)));
    if (sl.hasBulkProduct(state, tabIdToClose)) {
      dispatch(ac.espTileStreamUnsubscribeThunk(tabIdToClose));
      dispatch(ac.bulkClosed(tabIdToClose));
    }
    dispatch(ac.clientWorkspaceTabRemoved(tabIdToClose));
  };
}

export function clientWorkspaceTabTypeChangedThunk(tabId: string, tabType: TabType): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    switch (tabType) {
      case 'tiles':
        break;
      case 'bulkTrade':
        if (!sl.hasBulkProduct(state, tabId)) {
          dispatch(ac.bulkCreated(tabId));
        }
        dispatch(ac.espStreamRestartThunk(tabId));
        break;
      default:
        assertUnreachable(tabType, 'Tab type not handled');
    }
    dispatch(ac.clientWorkspaceTabTypeChanged(tabId, tabType));
  };
}

export function clientWorkspaceTabTypeToggleThunk(tabId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const { type } = sl.getClientWorkspaceTab(getState(), tabId);
    dispatch(ac.clientWorkspaceTabTypeChangedThunk(tabId, type === 'bulkTrade' ? 'tiles' : 'bulkTrade'));
  };
}

const shouldKeepTradeCaptureSessionInfos = (instrument: Instrument, newInstrument: InstrumentChoice): boolean => {
  // todo tighten type definition
  const instrumentTypeGroup1: Instrument[] = ['Cash', 'Swap'];
  const instrumentTypeGroup2: Instrument[] = ['Option', 'Accumulator', 'AmericanForward'];

  // should not keep session info as smartRfs can create different product
  // and we don't know in advance which ones
  if (instrument === 'SmartRfs') {
    return false;
  }

  if (instrumentTypeGroup1.includes(instrument) && instrumentTypeGroup2.includes(newInstrument as Instrument)) {
    return false;
  }
  if (instrumentTypeGroup2.includes(instrument) && instrumentTypeGroup1.includes(newInstrument as Instrument)) {
    return false;
  }
  return true;
};

export function tileInstrumentChangedThunk(tileId: string, newInstrument: InstrumentChoice): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    const { instrument } = sl.getTileState(state, tileId);
    if (instrument === newInstrument) {
      return;
    }

    // todo 1 those 2 booleans could also likely be ported to the optionAcumulatorTradeCapture epic
    const isOptionGrouped =
      newInstrument === 'Option' ? sl.getUserPreferenceData(getState()).optionStrategyGroupLegs : null;
    const isOptionGreekAndMktExpanded =
      newInstrument === 'Option' ? sl.getUserPreferenceData(getState()).optionExpandGreekAndMkt : null;

    const tradeCaptureSessionInfos = sl.getTradeCaptureSessionInfos(state, tileId);
    const currencyPair = fieldData(sl.getTileCurrencyPair(state, tileId)).data;

    dispatch(ac.gridItemResetHeightByInstrument(tileId, newInstrument));

    switch (instrument) {
      case 'Cash':
        if (newInstrument !== 'Cash') {
          dispatch(ac.cashTileStateCleaned(tileId));
        }
        break;
      case 'Option':
        if (newInstrument !== 'Option') {
          dispatch(ac.optionTileStateCleaned(tileId));
        }
        break;
      case 'Swap':
        if (newInstrument !== 'Swap') {
          dispatch(ac.swapTileStateCleaned(tileId));
        }
        break;
      case 'Accumulator':
        dispatch(ac.accumulatorTileStateCleaned(tileId));
        break;
      case 'AmericanForward':
        dispatch(ac.americanForwardTileStateCleaned(tileId));
    }

    const defaultTradeCaptureSessionInfos: TradeCaptureSessionInfos = {
      currentSessionId: null,
      tradeCaptureIdVersion: null,
    };

    dispatch(
      ac.tileInstrumentChanged(
        tileId,
        newInstrument,
        isOptionGrouped,
        isOptionGreekAndMktExpanded,
        shouldKeepTradeCaptureSessionInfos(instrument, newInstrument)
          ? tradeCaptureSessionInfos
          : defaultTradeCaptureSessionInfos,
        currencyPair,
      ),
    );

    if (newInstrument === 'Option' && isDefined(currencyPair)) {
      dispatch(ac.optionUseDefaultThunk(tileId, currencyPair));
    }
  };
}

export function clientWorkspaceClientChangedThunk(tabId: string, clientId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    /**
     * @todo Remove dependency on global action in epic 'links'
     */

    const state = getState();
    const { tiles, clientId: previousClientId } = sl.getClientWorkspaceTab(state, tabId);
    dispatch(ac.clientWorkspaceClientChanged(tabId, clientId, previousClientId!));

    if (sl.isUserInternalSales(state)) {
      dispatch(ac.orderRequestEmailsEpic(clientId, tabId));
    }

    if (sl.hasBulkProduct(state, tabId)) {
      dispatch(ac.bulkClientChangedThunk(tabId, clientId));
    }
    tiles
      .filter((tile) => sl.getTileOverriddenClientId(state, tile) === null)
      .forEach((tileId) => {
        const { instrument } = sl.getTileState(state, tileId);
        switch (instrument) {
          case 'Option':
            dispatch(ac.optionClientChangedThunk(tileId, clientId));
            break;
          case 'Cash':
            dispatch(ac.cashClientChangedThunk(tileId, clientId));
            break;
          case 'Swap':
            dispatch(ac.swapClientChangedThunk(tileId));
            break;
          case 'Bulk':
            throw new Error(
              'Bulk instrument should not be in the tile list, it will crash on workspace save otherwise',
            );
          case 'Accumulator':
            dispatch(ac.accumulatorClientChangedThunk(tileId, clientId));
            break;
          case 'AmericanForward':
            dispatch(ac.americanForwardClientChangedThunk(tileId, clientId));
            break;
          case 'Order':
            dispatch(ac.orderClientChangedThunk(tileId, clientId));
            break;
          case 'BlotterOrder':
            break; // Don't try to change client on submitted order tiles
          // smart Rfs has no client tile related logic
          case 'SmartRfs':
            break; // Don't try to change client on submitted order tiles
          default:
            assertUnreachable(instrument, 'Unhandled instrument when changing client');
        }
      });
  };
}

export function growlClosedThunk(growlId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const growlData = sl.getGrowls(state).find((s) => s.id === growlId);
    dispatch(ac.growlClosed(growlId));
    if (growlData === undefined) {
      return;
    }

    const executionData = sl.getExecutionById(state, growlId);
    const usedByOrderTile = sl.getOrderByExecutionId(state, growlId);

    if (executionData.status !== 'Pending' && usedByOrderTile === undefined) {
      // Cleanup execution only if it is not pending and ont used by an order tile
      // so that if it takes too long, we still have the data
      dispatch(ac.executionCleanup(growlId));
    }
  };
}

export function orderErrorDismissedThunk(executionId: string, quoteId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const growlData = sl.getGrowls(state).find((s) => s.id === executionId);

    // clean execution if not used by a growl
    if (growlData === undefined) {
      dispatch(ac.executionCleanup(executionId));
    } else {
      dispatch(ac.tileExecutionOverlayHidden(quoteId));
    }
  };
}

export function clientWorkspaceNewDefaultTileAddedThunk(
  clientWorkspaceId: string,
  tileId: string,
  position?: GridItemPosition,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const instruments = sl.getAvailableInstruments(getState()).filter(isTileInstrument);
    if (isEmpty(instruments)) {
      alert('Sorry, you are not allowed to trade any instruments.');
    } else {
      const instrument = instruments.includes('Cash') ? 'Cash' : instruments[0];
      dispatch(
        ac.clientWorkspaceNewTileAdded(
          clientWorkspaceId,
          tileId,
          instrument,
          instrument === 'Option' ? sl.getUserPreferenceData(getState()).optionStrategyGroupLegs : null,
          instrument === 'Option' ? sl.getUserPreferenceData(getState()).optionExpandGreekAndMkt : null,
          position,
        ),
      );
    }
  };
}

export function setupWorkspaceThunk(savedWorkspace: SavedWorkspace): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac, getNewGuid }) => {
    const state = getState();
    const productTypes = sl.getAvailableInstruments(state);
    const isInternalSales = sl.isUserInternalSales(state);

    dispatch(ac.setupWorkspaceCanTradeThunk(savedWorkspace.canTrade));
    dispatch(ac.blotterPanelHeightChanged(savedWorkspace.blotter.panelHeight));
    dispatch(ac.blotterTabChanged(savedWorkspace.blotter.activeTab));

    dispatch(ac.blotterTabMetadataChanged('cash', injectDefaultCashTab(savedWorkspace.blotter.cashTab)));

    dispatch(ac.blotterTabMetadataChanged('option', injectDefaultOptionTab(savedWorkspace.blotter.optionTab)));

    const defaultOrderTab = injectDefaultOrderTab(savedWorkspace.blotter.orderTab);

    dispatch(ac.blotterTabMetadataChanged('order', defaultOrderTab));

    dispatch(
      ac.blotterTabMetadataChanged('accumulator', injectDefaultAccumulatorTab(savedWorkspace.blotter.accumulatorTab)),
    );

    if (!isEmpty(productTypes)) {
      const restoredTabs = [...savedWorkspace.tabs]
        .sort(({ order: a }, { order: b }) => a - b) // sorts in ascending order
        .map((tab) => {
          const tabId = getNewGuid();
          dispatch(ac.setupWorkspaceTabThunk(tabId, tab));
          return [tabId, tab] as const;
        })
        .sort(([, { isActive: a1 }], _snd) => (a1 ? 1 : 0)); // put active tab first so that we restore its tiles first

      const currencyPairs = getSavedCurrencyPairs(savedWorkspace.tabs);

      if (!isEmpty(currencyPairs)) {
        dispatch(ac.retrieveClosedDates(currencyPairs));
      }

      const currencies = currencyPairs.flatMap((ccyPair) => ccyPair.split('/')).filter(distinct);

      if (!isEmpty(currencies)) {
        dispatch(ac.retrieveEspLimits(currencies));
      }

      dispatch(ac.restoreTabs(restoredTabs));
    } else if (isInternalSales) {
      dispatch(ac.toggleMifid2Panel(true));
    }
  };
}

function getSavedCurrencyPairs(tabs: readonly SavedTab[]): readonly string[] {
  return tabs
    .flatMap((tab) => tab.tiles.map(extract('currencyPair')).filter(isDefined).filter(isNonEmpty))
    .filter(distinct);
}

export function setupWorkspaceTabThunk(tabId: string, { clientId, tabType, tabName, isActive }: SavedTab): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const clients = sl.getAllUserClients(state);

    const isSaveClientExistFor = Object.values(clients).some((c) => c.companyId.toString() === clientId);
    const workspaceClientId = isSaveClientExistFor ? clientId : getDefaultClientId(clients);

    dispatch(ac.clientWorkspaceTabAdded(tabId, tabType, tabName, workspaceClientId));

    if (workspaceClientId !== null && sl.isUserInternalSales(state)) {
      dispatch(ac.orderRequestEmailsEpic(workspaceClientId, tabId));
    }

    if (isActive) {
      dispatch(ac.clientWorkspaceTabSwitched(tabId));
    }

    if (tabType === 'bulkTrade') {
      dispatch(ac.bulkCreated(tabId));
    }
  };
}

function mapToInstrumentChoice({
  instrument,
  productName,
}: Pick<SavedTile, 'instrument' | 'productName'>): InstrumentChoice {
  if (instrument === 'Accumulator') {
    return productName === 'FxForwardAccumulator' ? 'ForwardAccumulator' : 'TargetAccumulator';
  } else {
    return instrument;
  }
}

export function restoreTabs(restoredTabs: ReadonlyArray<readonly [string, SavedTab]>): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl, getNewGuid }) => {
    const allSavedTiles: Array<readonly [string, SavedTile]> = [];
    restoredTabs.forEach(([tabId, { tiles }]) => {
      if (isEmpty(tiles)) {
        return;
      }

      const state = getState();
      const ccyPairs = sl.getAllCcyPairs(state);
      const productTypes = sl.getAvailableInstruments(state);
      const productsAccess = sl.getProductsAccess(state);

      const tilesToRestore = [...tiles]
        .sort(({ position: a }, { position: b }) => a.top - b.top)
        .filter(
          (savedTile) =>
            productTypes.includes(mapToInstrumentChoice(savedTile)) &&
            isProductAuthorized(productsAccess, savedTile.productName),
        );

      const isOptionGrouped = sl.getUserPreferenceData(state).optionStrategyGroupLegs;
      const isOptionGreekAndMktExpanded = sl.getUserPreferenceData(state).optionExpandGreekAndMkt;

      const restoredTiles = tilesToRestore
        .filter(({ currencyPair }) => !currencyPair || ccyPairs[currencyPair] !== undefined)
        .map((savedTile) => {
          const tileId = getNewGuid();

          dispatch(
            ac.clientWorkspaceTileRestored(tabId, tileId, savedTile, isOptionGrouped, isOptionGreekAndMktExpanded),
          );
          return [tileId, savedTile] as const;
        });

      allSavedTiles.push(...restoredTiles);
    });

    dispatch(ac.restoreTileThunk(allSavedTiles));
  };
}

export function restoreTileThunk(restoredTiles: Array<readonly [string, SavedTile]>): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const isCurrencyPairUndefined = (currencyPair?: string | null) =>
      isNotDefined(currencyPair) || sl.getCurrencyPairDetails(getState(), currencyPair) === null;

    const cashRestoredTiles: RestoredTiles<CashSavedTile> = {};
    const optionRestoredTiles: RestoredTiles<OptionSavedTile> = {};
    const americanForwardRestoredTiles: RestoredTiles<AmericanForwardSavedTile> = {};
    const swapRestoredTiles: RestoredTiles<SwapSavedTile> = {};
    const accuRestoredTiles: RestoredTiles<AccumulatorSavedTile> = {};

    restoredTiles
      .filter(([, tile]) => !isCurrencyPairUndefined(tile.currencyPair))
      .forEach(([tileId, tile]) => {
        switch (tile.instrument) {
          case 'Cash':
            cashRestoredTiles[tileId] = tile;
            break;
          case 'Option':
            optionRestoredTiles[tileId] = tile;
            if (tile.isStrategy) {
              dispatch(ac.optionToggleStrategyThunk(tileId, true));
            }
            break;
          case 'AmericanForward':
            dispatch(ac.americanForwardSolvingTypeChanged(tileId, tile.solvingType as PossibleSolvingPremium))
            americanForwardRestoredTiles[tileId] = tile;
            break;
          case 'Swap':
            swapRestoredTiles[tileId] = tile;
            break;
          case 'Accumulator':
            accuRestoredTiles[tileId] = tile;
            break;
          // in case of SmartRfs we do not restore any form state as the tile is by definition empty
          case 'SmartRfs':
            break;
          default:
            assertUnreachable(tile, 'Unhandled instrument');
        }
      });

    dispatch(ac.cashTileRestoreEpic(cashRestoredTiles));
    dispatch(ac.optionTileRestoreEpic(optionRestoredTiles));
    dispatch(ac.americanForwardTileRestoreEpic(americanForwardRestoredTiles));
    dispatch(ac.swapTileRestoreEpic(swapRestoredTiles));
    dispatch(ac.accumulatorTileRestoreEpic(accuRestoredTiles));
  };
}

export function clientWorkspaceTileZoomedThunk(quoteId: string): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const tabId = sl.getClientWorkspaceActiveTab(getState());
    dispatch(ac.clientWorkspaceTileZoomed(tabId!, quoteId));
  };
}

export function clientWorkspaceTileMinimizedThunk(): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const tabId = sl.getClientWorkspaceActiveTab(getState());
    dispatch(ac.clientWorkspaceTileMinimized(tabId!));
  };
}

export function toggleLockTraddingThunk(): Thunk<void> {
  return (dispatch, getState, { selectors: { getUserInfo }, actionCreators: ac }) => {
    const { canTrade } = getUserInfo(getState());
    if (canTrade) {
      dispatch(ac.toggleTradingDisabled());
    }
  };
}

export function saveWorkspaceThunk(): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac, metaSelectors: msl }) => {
    const state = getState();
    /**
     * @todo test
     */
    if (sl.getSaveState(state) !== 'READY') {
      return;
    }
    dispatch(ac.saveWorkspaceRequested());
    const workspaceToSave = msl.getWorkspaceToSave(state);
    dispatch(ac.saveWorkspaceRequestedEpic(workspaceToSave));
  };
}

const waitstateClientId = '20252';

function getDefaultClientId(clients: ClientMap, clientId: string | null = null): string | null {
  const clientsList = Object.values(clients);
  const hasClients = !isEmpty(clientsList);
  return clientId === null && hasClients
    ? clients[waitstateClientId]
      ? waitstateClientId
      : clientsList[0].companyId.toString()
    : clientId;
}

function isProductAuthorized(productsAccess: ProductsAccess, productName: ProductName) {
  const authorization: Record<ProductName, boolean> = {
    FxSpot: productsAccess.spot,
    FxFwd: productsAccess.forward,
    FxNdf: productsAccess.forward && productsAccess.nonDeliverable,
    FxSwap: productsAccess.swap,
    FxNdSwap: productsAccess.swap && productsAccess.nonDeliverable,
    FxPredeliver: productsAccess.swap,
    FxRollover: productsAccess.swap,
    FxOption: productsAccess.option,
    FxOrder: productsAccess.takeProfit || productsAccess.stopLoss || productsAccess.callOrder,
    FxBulk: false,
    FxTargetAccumulator: productsAccess.targetAccu,
    FxForwardAccumulator: productsAccess.forwardAccu,
    FxAmericanForward: productsAccess.americanForward,
    // for now smartRfs is always authorized
    // as the underlying created product has specific access (option for now)
    FxSmartRfs: true,
  };

  return authorization[productName];
}

export function setupWorkspaceCanTradeThunk(canTrade: boolean | null): Thunk<void> {
  return (dispatch, getState, { selectors: { getUserInfoCanTrade }, actionCreators: ac }) => {
    if (canTrade === null) {
      return;
    }
    const userCanTrade = getUserInfoCanTrade(getState());
    const actualcanTrade = userCanTrade ? canTrade : userCanTrade;
    dispatch(ac.toggleTradingReceived(actualcanTrade));
  };
}
