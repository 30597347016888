import { getAmericanForwardValue } from './factories';

export const getAmericanForwardCurrency1 = getAmericanForwardValue('currency1');
export const getAmericanForwardCurrency2 = getAmericanForwardValue('currency2');
export const getAmericanForwardProductName = getAmericanForwardValue('productName');

export const getAmericanForwardPossibleMarketPlaces = getAmericanForwardValue('possibleMarketPlaces');
export const getAmericanForwardPossibleHedgeTypes = getAmericanForwardValue('possibleHedgeTypes');
export const getAmericanForwardHedgePaymentDate = getAmericanForwardValue('hedgePaymentDate');

export const getAmericanForwardSolvable = getAmericanForwardValue('solvable');
