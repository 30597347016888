import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type {
  OptionHedgeSavedTile,
  OptionLegSavedTile,
  OptionVanillaLegSavedTile,
  SavedBlotter,
  SavedBlotterTab,
  SavedTile,
  SavedTilePosition,
  SavedTileSize,
  SavedWorkspace,
  SmartRfsSavedTile,
} from 'api/workspaceService/model';
import { assertUnreachable, isDefined, isNonEmpty } from '@sgme/fp';
import type { BlotterTabMetadata } from 'state/blotter/blotterModel';
import { colWidth } from 'styles/constants';
import { pick } from 'utils/object';
import type { Instrument } from 'state/referenceData';
import { getAllLegsInputs } from 'state/share/productMappers/optionMappers';
import { stringToNumber } from 'utils/numberInputFormats';
import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type {
  FxOptionLegInputs,
  IFxOptionTypedStrategyLegInputs,
  IFxVanillaLegInputs,
} from 'state/fxOptions/model/optionsLegs';
import type { Collection } from '../../../typings/utils';
import { getAmericanForwardSolvingType, getAmericanForwardState } from '../../../state/fxAmericanForward/selectors/';

const dateBR = 'BR';

function toCol(size: number) {
  return Math.round(size / colWidth);
}

function shouldSaveTile(instrument: Instrument): boolean {
  switch (instrument) {
    case 'Cash':
    case 'Swap':
    case 'Option':
    case 'Accumulator':
    case 'Bulk':
    case 'AmericanForward':
    case 'SmartRfs':
      return true;
    case 'Order':
    case 'BlotterOrder':
      return false;
    default:
      assertUnreachable(instrument, 'instrument not handled');
  }
}

const makeGetTileToSave =
  (sl: Selectors, state: AppState, tabId: string) =>
  (tileId: string): SavedTile | undefined => {
    const { instrument, overrideClientId } = sl.getTileState(state, tileId);

    if (!shouldSaveTile(instrument)) {
      return;
    }

    const { top, left } = sl.getGridItemPosition(state, tabId, tileId);
    const { height, width } = sl.getGridItemSize(state, tabId, tileId);

    const position: SavedTilePosition = {
      top,
      colPosition: toCol(left),
    };

    const size: SavedTileSize = { height, colSize: toCol(width) };

    switch (instrument) {
      case 'Cash':
        return makeCashTileToSave(sl, state, tileId, overrideClientId, position, size);
      case 'Option':
        return makeOptionTileToSave(sl, state, tileId, overrideClientId, position, size);
      case 'AmericanForward':
        return makeAmericanForwardTileToSave(sl, state, tileId, position, size);
      case 'Swap':
        return makeSwapTileToSave(sl, state, tileId, overrideClientId, position, size);
      case 'Accumulator':
        return makeAccumulatorTileToSave(sl, state, tileId, position, size);
      // smartRfs does not save any state for now
      case 'SmartRfs':
        return makeSmartRfsTileToSave(position, size);
      default:
        return undefined;
    }
  };

const makeSmartRfsTileToSave = (position: SavedTilePosition, size: SavedTileSize): SmartRfsSavedTile | undefined => ({
  position,
  size,
  instrument: 'SmartRfs',
  productName: 'FxSmartRfs',
});

function makeCashTileToSave(
  sl: Selectors,
  state: AppState,
  tileId: string,
  overrideClientId: string | null,
  position: SavedTilePosition,
  size: SavedTileSize,
): SavedTile | undefined {
  const currencyPair = sl.getCashCurrencyPair(state, tileId).value;

  const {
    values: { maturityDate, maturityDateTenor, productName, amountCurrency, amount, isNonDeliverable = false },
  } = sl.getCashState(state, tileId);

  if (currencyPair === null) {
    return {
      instrument: 'Cash',
      overrideClientId: overrideClientId ?? undefined,
      productName,
      currencyPair,
      position,
      size,
      isNonDeliverable,
    };
  } else {
    return {
      instrument: 'Cash',
      overrideClientId: overrideClientId ?? undefined,
      productName,
      currencyPair,
      position,
      size,
      maturityDateTenor:
        maturityDateTenor === dateBR ? getValueOrUndefined(maturityDate) : getValueOrUndefined(maturityDateTenor),
      amountCurrency,
      amount: amount ?? undefined,
      isNonDeliverable,
    };
  }
}

function makeOptionTileToSave(
  sl: Selectors,
  state: AppState,
  tileId: string,
  overrideClientId: string | null,
  position: SavedTilePosition,
  size: SavedTileSize,
): SavedTile | undefined {
  const {
    values: { currencyPair, hedgeType, premiumDate, premiumDateTenor },
    displayPriceType: [priceTypeCcy, priceType],
    isStrategy,
    orientation,
  } = sl.getOptionState(state, tileId);

  function isVanillaLegInput(leg: Partial<FxOptionLegInputs>): leg is IFxVanillaLegInputs {
    return leg.productName === 'Vanilla';
  }

  const stripOptionIdFromElementId = (legId: string) => legId.substring(legId.indexOf('/') + 1);

  const allLegsInputs = getAllLegsInputs(sl, state, tileId);
  const allHedgesInputs = sl.getAllHedgesOfOptionWithId(state, tileId);

  // TODO: remove the complexity
  const updatedLegsInSavedTileFormat = Object.entries(allLegsInputs).reduce((acc, [legId, leg]) => {
    if (isVanillaLegInput(leg)) {
      acc[stripOptionIdFromElementId(legId)] = mapVanillaLeg(leg);
    } else {
      acc[stripOptionIdFromElementId(legId)] = {
        ...(leg as IFxOptionTypedStrategyLegInputs),
        legIds: (leg as IFxOptionTypedStrategyLegInputs).legIds.map(stripOptionIdFromElementId),
      };
    }
    return acc;
  }, {} as Collection<OptionLegSavedTile>);

  // simple version
  const updatedHedgesInSavedTileFormat = allHedgesInputs.reduce((acc, [hedgeId, hedge]) => {
    acc[stripOptionIdFromElementId(hedgeId)] = {
      amount: hedge.values.amount ?? undefined,
      currency: hedge.values.currency ?? undefined,
      rate: hedge.values.rate ?? undefined,
    };

    return acc;
  }, {} as Collection<OptionHedgeSavedTile>);

  return {
    instrument: 'Option',
    overrideClientId: overrideClientId ?? undefined,
    productName: 'FxOption',
    currencyPair,
    position,
    size,
    isStrategy,
    orientation,
    priceType,
    priceTypeCcy,
    premiumDate: premiumDateTenor === dateBR ? getValueOrUndefined(premiumDate) : undefined,
    premiumDateTenor: premiumDateTenor === dateBR ? undefined : getValueOrUndefined(premiumDateTenor),
    legs: updatedLegsInSavedTileFormat,
    hedgeType,
    hedges: updatedHedgesInSavedTileFormat,
    legIds: [`0`],
  };
}

function makeAmericanForwardTileToSave(
  _sl: Selectors,
  state: AppState,
  tileId: string,
  position: SavedTilePosition,
  size: SavedTileSize,
): SavedTile | undefined {
  // settlement cut off?
  const {
    values: {
      productName,
      currencyPair,
      premiumTypeString,
      priceCurrency,
      premiumDate,
      premiumDateTenor,
      amount,
      amountCurrency,
      hedgeRate,
      hedgeType,
      marketPlace,
      markupCurrency,
      premiumPaymentAmount,
      side,
      startDate,
      startDateTenor,
      deliveryDate,
      deliveryDateTenor,
      expiryDate,
      expiryDateTenor,
      forwardRate,
      hedgeAmount,
      hedgeCurrency,
      hedgePaymentDate,
    },
  } = getAmericanForwardState(state, tileId);

  const solvingType = getAmericanForwardSolvingType(state, tileId) ?? 'zeroCost';

  return {
    instrument: 'AmericanForward',
    productName,
    amount,
    position,
    size,
    currencyPair: getValueOrUndefined(currencyPair),
    premiumTypeString,
    priceCurrency,
    premiumDate: premiumDateTenor === dateBR ? getValueOrUndefined(premiumDate) : undefined,
    premiumDateTenor: premiumDateTenor === dateBR ? undefined : getValueOrUndefined(premiumDateTenor),
    amountCurrency,
    premiumPaymentAmount,
    side,
    startDate,
    startDateTenor,
    expiryDate,
    expiryDateTenor,
    deliveryDate,
    deliveryDateTenor,
    marketPlace,
    rate: forwardRate,
    hedgeCurrency,
    hedgeAmount,
    hedgePaymentDate,
    hedgeType,
    hedgeRate,
    markupCurrency,
    solvingType,
  };
}

function makeSwapTileToSave(
  sl: Selectors,
  state: AppState,
  tileId: string,
  overrideClientId: string | null,
  position: SavedTilePosition,
  size: SavedTileSize,
): SavedTile | undefined {
  const {
    values: {
      nearPaymentDate,
      nearPaymentDateTenor,
      farPaymentDate,
      farPaymentDateTenor,
      productName,
      amountCurrency,
      farAmount,
      nearAmount,
      currencyPair,
      isNonDeliverable,
    },
  } = sl.getSwapState(state, tileId);

  if (currencyPair === null) {
    return {
      instrument: 'Swap',
      overrideClientId: overrideClientId ?? undefined,
      productName,
      currencyPair,
      position,
      size,
      isNonDeliverable,
    };
  } else {
    return {
      instrument: 'Swap',
      overrideClientId: overrideClientId ?? undefined,
      productName: isNonDeliverable ? 'FxNdSwap' : productName,
      isNonDeliverable,
      currencyPair,
      position,
      size,
      nearPaymentDateTenor:
        nearPaymentDateTenor === dateBR
          ? getValueOrUndefined(nearPaymentDate)
          : getValueOrUndefined(nearPaymentDateTenor),
      farPaymentDateTenor:
        farPaymentDateTenor === dateBR ? getValueOrUndefined(farPaymentDate) : getValueOrUndefined(farPaymentDateTenor),
      amountCurrency,
      nearAmount: nearAmount ?? undefined,
      farAmount: farAmount ?? undefined,
    };
  }
}

function makeAccumulatorTileToSave(
  sl: Selectors,
  state: AppState,
  tileId: string,
  position: SavedTilePosition,
  size: SavedTileSize,
): SavedTile | undefined {
  // settlement cut off?
  const {
    values: {
      productName,
      currencyPair,
      priceType,
      priceCurrency,
      premiumDate,
      premiumDateTenor,
      hedgeType,
      way,
      amount,
      amountCurrency,
      amountSplitType,
      leverage,
      step,
      accuType,
      // TODO ABO, SGEFX-5007: harmonize eki variant name (eki, ekiTrigger, step)
      // @ts-ignore
      eki,
      ekiDown,
      ekiUp,
      strikeUp,
      strikeDown,
      pivot,
      akoTrigger,
      ekiTrigger,
      strike,
      targetProfitType,
      target,
      expiryDate,
      expiryTenor,
      fixingFrequency,
      settlementMode,
      cashSettlementCurrency,
      firstFixingDate,
      firstFixingDateTenor,
      isCrossed,
      crossCurrency,
      fixingReference1,
      fixingReference2,
      settlementFrequency,
    },
  } = sl.getAccumulatorState(state, tileId);

  return {
    instrument: 'Accumulator',
    productName,
    position,
    size,
    currencyPair: getValueOrUndefined(currencyPair),
    priceType,
    priceCurrency,
    premiumDate: premiumDateTenor === dateBR ? getValueOrUndefined(premiumDate) : undefined,
    premiumDateTenor: premiumDateTenor === dateBR ? undefined : getValueOrUndefined(premiumDateTenor),
    hedgeType,
    way,
    amount: getValueOrUndefined(amount),
    amountCurrency,
    amountSplitType,
    leverage: getValueOrUndefined(leverage),
    step: productName === 'FxForwardAccumulator' ? getValueOrUndefined(step) : undefined,
    accuType,
    eki,
    ekiDown: isDefined(ekiDown) ? ekiDown : undefined,
    ekiUp: isDefined(ekiUp) ? ekiUp : undefined,
    strikeDown: isDefined(strikeDown) ? strikeDown : undefined,
    strikeUp: isDefined(strikeUp) ? strikeUp : undefined,
    pivot: isDefined(pivot) ? pivot : undefined,
    akoTrigger: productName === 'FxForwardAccumulator' && isDefined(akoTrigger) ? akoTrigger : undefined,
    ekiTrigger: productName === 'FxForwardAccumulator' ? getValueOrUndefined(ekiTrigger) : undefined,
    strike: getValueOrUndefined(strike),
    targetProfitType: targetProfitType ?? undefined,
    target: getValueOrUndefined(target),
    expiryDate: expiryTenor === dateBR ? getValueOrUndefined(expiryDate) : undefined,
    expiryTenor: expiryTenor === dateBR ? undefined : getValueOrUndefined(expiryTenor), // date absolue
    fixingFrequency: getValueOrUndefined(fixingFrequency),
    settlementMode,
    cashSettlementCurrency,
    firstFixingDate: firstFixingDateTenor === dateBR ? getValueOrUndefined(firstFixingDate) : undefined,
    firstFixingDateTenor: firstFixingDateTenor === dateBR ? undefined : getValueOrUndefined(firstFixingDateTenor),
    isCrossed,
    crossCurrency: getValueOrUndefined(crossCurrency),
    fixingReference1: getValueOrUndefined(fixingReference1),
    fixingReference2: getValueOrUndefined(fixingReference2),
    settlementFrequency: productName === 'FxForwardAccumulator' ? getValueOrUndefined(settlementFrequency) : undefined,
  };
}

function mapVanillaLeg(argLeg: Partial<IFxVanillaLegInputs>): OptionVanillaLegSavedTile {
  return {
    productName: 'Vanilla',
    side: argLeg.side ?? undefined,
    expiryDate: argLeg.expiryDateTenor === dateBR ? getValueOrUndefined(argLeg.expiryDate) : undefined,
    expiryDateTenor: argLeg.expiryDateTenor === dateBR ? undefined : getValueOrUndefined(argLeg.expiryDateTenor),
    notionalAmount: stringToNumber(argLeg.notionalAmount ?? null) ?? undefined,
    notionalCurrency: argLeg.notionalCurrency,
    putOrCall: argLeg.optionType ?? undefined,
    strikePrice: getValueOrUndefined(argLeg.strike),
    marketPlace: getValueOrUndefined(argLeg.marketPlace),
    settlementType: argLeg.settlementType ?? undefined,
    cashSettlementCurrency: getValueOrUndefined(argLeg.cashSettlementCurrency),
    fixingReference1: getValueOrUndefined(argLeg.fixingReference1),
  };
}

// WHY ??????
function getValueOrUndefined(value: string | null | undefined) {
  if (isDefined(value) && isNonEmpty(value)) {
    return value;
  } else {
    return undefined;
  }
}

function isNotUndefinedTuple<T>(tuple: [string, T | undefined]): tuple is [string, T] {
  return tuple[1] !== undefined;
}

export const getWorkspaceToSaveWith: MapStateToMetadataHOF<SavedWorkspace, unknown, AppState, Selectors> =
  (sl) => (state) => {
    const canTrade = sl.userCanTrade(state);
    const tabs = sl.getClientWorkspaceTabs(state);
    const activeTab = sl.getClientWorkspaceActiveTab(state);

    const tabsToSave = Object.entries(tabs)
      .filter(isNotUndefinedTuple)
      .map(([tabId, tab], index) => ({
        tabName: tab.name,
        tabType: tab.type,
        order: index,
        clientId: tab.clientId,
        isActive: activeTab === tabId,
        tiles: tab.tiles.map(makeGetTileToSave(sl, state, tabId)).filter(isDefined),
      }));

    const blotter = getBlotterToSave(sl, state);

    return { version: 9, canTrade, tabs: tabsToSave, blotter };
  };

const pickPropertiesToSave = pick('colId', 'hide', 'width');

const mapToSavedTab = (tab: BlotterTabMetadata): SavedBlotterTab => ({
  autoColumState: tab.autoColumState,
  columnsState: tab.columnsState.map(pickPropertiesToSave),
  sortModel: tab.sortModel,
});

function getBlotterToSave(sl: Selectors, state: AppState): SavedBlotter {
  const cashMetadata = sl.getBlotterTabMetadata(state, 'cash');
  const optionMetadata = sl.getBlotterTabMetadata(state, 'option');
  const orderMetadata = sl.getBlotterTabMetadata(state, 'order');
  const accumulatorMetadata = sl.getBlotterTabMetadata(state, 'accumulator');

  return {
    panelHeight: sl.getBlotterPanelHeight(state),
    activeTab: sl.getBlotterActiveTab(state),
    cashTab: mapToSavedTab(cashMetadata),
    optionTab: mapToSavedTab(optionMetadata),
    orderTab: mapToSavedTab(orderMetadata),
    accumulatorTab: mapToSavedTab(accumulatorMetadata),
  };
}
